import React from 'react'
import { Helmet } from 'react-helmet'

import { routes, externalLinks } from '@/data'
import logo from '../svg/pampered-lash-logo.svg'

type NavItem = {
  id: string
  link: {
    url: string
    label: string
  }
}

type NavLinkProps = { items: NavItem[] }

const directionsItem = ({ id }) => id !== '2'
const navItems: NavItem[] = [
  {
    id: '1',
    link: {
      label: externalLinks.appointments.label,
      url: externalLinks.appointments.url,
    },
  },
  {
    id: '2',
    link: {
      label: routes.directions.label,
      url: routes.directions.path,
    },
  },
].filter(directionsItem)

function NavList({ items }: NavLinkProps) {
  return (
    <div className="rounded-xl border border-mauve-100 overflow-hidden">
      {items && items.length > 0 ? (
        <ul>
          {items.map(({ id, link }) => (
            <li key={id} className="border-b border-mauve-100 last:border-b-0">
              <a
                className="w-full p-4 transition-colors ease-in-out duration-200 text-center text-mauve-900 text-lg md:text-sm tracking-widest font-medium block uppercase bg-white/20 hover:bg-white/10"
                href={link.url}
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Pampered Lash Studio</title>
      </Helmet>
      <main className="flex flex-col items-center justify-center h-full py-8">
        <div className="max-w-xs md:max-w-lg mx-auto">
          <img className="h-auto w-full" src={logo} alt="Pampered Lash Logo" />
        </div>
        <div className="w-full p-4 mt-auto">
          <NavList items={navItems} />
        </div>
      </main>
    </>
  )
}
